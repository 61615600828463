import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _88998a58 = () => interopDefault(import('../src/pages/foo.vue' /* webpackChunkName: "pages/foo" */))
const _36cdfeee = () => interopDefault(import('../src/pages/ie/index.js' /* webpackChunkName: "pages/ie/index" */))
const _73e0f6fd = () => interopDefault(import('../src/pages/ie/ie.vue' /* webpackChunkName: "pages/ie/ie" */))
const _3b105fc0 = () => interopDefault(import('../src/modules/admin/pages/index' /* webpackChunkName: "" */))
const _2d5ebbd4 = () => interopDefault(import('../src/modules/angels/pages/frontend/index' /* webpackChunkName: "" */))
const _0d176200 = () => interopDefault(import('../src/modules/angels/pages/frontend/thank-you' /* webpackChunkName: "" */))
const _f1126706 = () => interopDefault(import('../src/modules/appeals/pages/frontend/show' /* webpackChunkName: "" */))
const _aa739564 = () => interopDefault(import('../src/modules/auth/pages/register' /* webpackChunkName: "" */))
const _5a32609e = () => interopDefault(import('../src/modules/auth/pages/login' /* webpackChunkName: "" */))
const _1fb764fa = () => interopDefault(import('../src/modules/auth/pages/forgot-password' /* webpackChunkName: "" */))
const _62ecc3c4 = () => interopDefault(import('../src/modules/auth/pages/reset-password' /* webpackChunkName: "" */))
const _b7e00d3c = () => interopDefault(import('../src/modules/basket/pages/frontend/index' /* webpackChunkName: "" */))
const _ae14a1da = () => interopDefault(import('../src/modules/basket/pages/frontend/index/basket' /* webpackChunkName: "" */))
const _5aace659 = () => interopDefault(import('../src/modules/basket/pages/frontend/index/payment' /* webpackChunkName: "" */))
const _d4c15a64 = () => interopDefault(import('../src/modules/basket/pages/frontend/index/payment-details' /* webpackChunkName: "" */))
const _ba24c708 = () => interopDefault(import('../src/modules/basket/pages/frontend/index/login' /* webpackChunkName: "" */))
const _6bd9b2ea = () => interopDefault(import('../src/modules/basket/pages/frontend/index/receipt' /* webpackChunkName: "" */))
const _c4075e0c = () => interopDefault(import('../src/modules/cms/pages/index' /* webpackChunkName: "" */))
const _bee10124 = () => interopDefault(import('../src/modules/cms/pages/news/index' /* webpackChunkName: "" */))
const _16ac3e12 = () => interopDefault(import('../src/modules/cms/pages/news/item' /* webpackChunkName: "" */))
const _87523768 = () => interopDefault(import('../src/modules/donations/pages/frontend/donate' /* webpackChunkName: "" */))
const _5b32d92a = () => interopDefault(import('../src/modules/entrepreneurs/pages/admin/index' /* webpackChunkName: "" */))
const _70678983 = () => interopDefault(import('../src/modules/entrepreneurs/pages/admin/create' /* webpackChunkName: "" */))
const _27cfcc51 = () => interopDefault(import('../src/modules/entrepreneurs/pages/admin/edit' /* webpackChunkName: "" */))
const _7b1282b4 = () => interopDefault(import('../src/modules/entrepreneurs/pages/admin/select-mfi' /* webpackChunkName: "" */))
const _ef1e4292 = () => interopDefault(import('../src/modules/grant-repayments/pages/admin/due-index' /* webpackChunkName: "" */))
const _63c59115 = () => interopDefault(import('../src/modules/grant-repayments/pages/admin/due-mfi' /* webpackChunkName: "" */))
const _672ab805 = () => interopDefault(import('../src/modules/grant-repayments/pages/admin/archive-index' /* webpackChunkName: "" */))
const _6a2165e3 = () => interopDefault(import('../src/modules/grant-repayments/pages/admin/archive-mfi' /* webpackChunkName: "" */))
const _7445972e = () => interopDefault(import('../src/modules/grant-reporting/pages/admin/index' /* webpackChunkName: "" */))
const _6c139dbe = () => interopDefault(import('../src/modules/grant-reporting/pages/admin/show' /* webpackChunkName: "" */))
const _2e7abc20 = () => interopDefault(import('../src/modules/grant-reporting/pages/admin/no-current-report' /* webpackChunkName: "" */))
const _4e5dafc4 = () => interopDefault(import('../src/modules/grant-reporting/pages/admin/archive' /* webpackChunkName: "" */))
const _f1bd6e40 = () => interopDefault(import('../src/modules/grants/pages/frontend/home' /* webpackChunkName: "" */))
const _5625bd69 = () => interopDefault(import('../src/modules/grants/pages/frontend/search' /* webpackChunkName: "" */))
const _f1b3a204 = () => interopDefault(import('../src/modules/grants/pages/frontend/show' /* webpackChunkName: "" */))
const _2d10c936 = () => interopDefault(import('../src/modules/grants/pages/admin/index' /* webpackChunkName: "" */))
const _7b1ab02e = () => interopDefault(import('../src/modules/grants/pages/admin/select-entrepreneur' /* webpackChunkName: "" */))
const _6b040d18 = () => interopDefault(import('../src/modules/grants/pages/admin/create' /* webpackChunkName: "" */))
const _01723126 = () => interopDefault(import('../src/modules/grants/pages/admin/edit' /* webpackChunkName: "" */))
const _605167cd = () => interopDefault(import('../src/modules/homepage/pages/index' /* webpackChunkName: "" */))
const _837b0632 = () => interopDefault(import('../src/modules/loans/pages/frontend/search' /* webpackChunkName: "" */))
const _0dc8b5fc = () => interopDefault(import('../src/modules/loans/pages/frontend/show' /* webpackChunkName: "" */))
const _bff61098 = () => interopDefault(import('../src/modules/loans/pages/admin/index' /* webpackChunkName: "" */))
const _5a24aa2c = () => interopDefault(import('../src/modules/loans/pages/admin/select-entrepreneur' /* webpackChunkName: "" */))
const _5695ae5a = () => interopDefault(import('../src/modules/loans/pages/admin/create' /* webpackChunkName: "" */))
const _37c18a30 = () => interopDefault(import('../src/modules/loans/pages/admin/edit' /* webpackChunkName: "" */))
const _7fd72f2a = () => interopDefault(import('../src/modules/loans/pages/admin/messages' /* webpackChunkName: "" */))
const _2d6e1ad0 = () => interopDefault(import('../src/modules/marketing/pages/frontend/shop' /* webpackChunkName: "" */))
const _c3455caa = () => interopDefault(import('../src/modules/marketing/pages/frontend/welcome-answer' /* webpackChunkName: "" */))
const _71056cbc = () => interopDefault(import('../src/modules/mfis/pages/admin/index' /* webpackChunkName: "" */))
const _487df33a = () => interopDefault(import('../src/modules/mfis/pages/admin/edit' /* webpackChunkName: "" */))
const _19fc6034 = () => interopDefault(import('../src/modules/mfis/pages/admin/admins' /* webpackChunkName: "" */))
const _16c8e6c1 = () => interopDefault(import('../src/modules/mfis/pages/admin/mfi-info-messages/index' /* webpackChunkName: "" */))
const _42cab63b = () => interopDefault(import('../src/modules/mfis/pages/admin/mfi-info-messages/edit' /* webpackChunkName: "" */))
const _5da27a60 = () => interopDefault(import('../src/modules/offsetting/pages/admin/index' /* webpackChunkName: "" */))
const _42fbf260 = () => interopDefault(import('../src/modules/offsetting/pages/admin/archive' /* webpackChunkName: "" */))
const _8e91c0a2 = () => interopDefault(import('../src/modules/offsetting/pages/admin/show' /* webpackChunkName: "" */))
const _6c7a4a22 = () => interopDefault(import('../src/modules/offsetting/pages/admin/no-current-report' /* webpackChunkName: "" */))
const _4b19522c = () => interopDefault(import('../src/modules/referrals/pages/frontend/make-referral' /* webpackChunkName: "" */))
const _7c00308f = () => interopDefault(import('../src/modules/referrals/pages/frontend/referral' /* webpackChunkName: "" */))
const _54e27602 = () => interopDefault(import('../src/modules/repayments/pages/admin/index' /* webpackChunkName: "" */))
const _0c8625af = () => interopDefault(import('../src/modules/repayments/pages/admin/archive' /* webpackChunkName: "" */))
const _7ebfb67b = () => interopDefault(import('../src/modules/reporting/pages/reporting' /* webpackChunkName: "" */))
const _9a516ee6 = () => interopDefault(import('../src/modules/reporting/pages/reporting/donations' /* webpackChunkName: "" */))
const _0a0978f7 = () => interopDefault(import('../src/modules/reporting/pages/reporting/reconciliation' /* webpackChunkName: "" */))
const _2cd35c56 = () => interopDefault(import('../src/modules/reporting/pages/reporting/user-changes' /* webpackChunkName: "" */))
const _67e6a9d8 = () => interopDefault(import('../src/modules/reporting/pages/reporting/user-data' /* webpackChunkName: "" */))
const _558495f8 = () => interopDefault(import('../src/modules/reporting/pages/reporting/gift-aid-data' /* webpackChunkName: "" */))
const _07d3c65e = () => interopDefault(import('../src/modules/reporting/pages/reporting/entrepreneur-and-loan-data' /* webpackChunkName: "" */))
const _15f11c5a = () => interopDefault(import('../src/modules/site-settings/pages/index' /* webpackChunkName: "" */))
const _4a9a6cfa = () => interopDefault(import('../src/modules/site-settings/pages/index/loan-activity-types' /* webpackChunkName: "" */))
const _3396316a = () => interopDefault(import('../src/modules/site-settings/pages/index/user-sources' /* webpackChunkName: "" */))
const _e6f01e3c = () => interopDefault(import('../src/modules/site-settings/pages/index/user-sources/list' /* webpackChunkName: "" */))
const _7b6be040 = () => interopDefault(import('../src/modules/site-settings/pages/index/user-sources/create' /* webpackChunkName: "" */))
const _e6f6a364 = () => interopDefault(import('../src/modules/site-settings/pages/index/user-sources/edit' /* webpackChunkName: "" */))
const _5fb7702e = () => interopDefault(import('../src/modules/teams/pages/frontend/create' /* webpackChunkName: "" */))
const _6fdc6c97 = () => interopDefault(import('../src/modules/teams/pages/frontend/manage' /* webpackChunkName: "" */))
const _4579ab60 = () => interopDefault(import('../src/modules/teams/pages/frontend/index' /* webpackChunkName: "" */))
const _4776a0b7 = () => interopDefault(import('../src/modules/teams/pages/frontend/profile' /* webpackChunkName: "" */))
const _860369e8 = () => interopDefault(import('../src/modules/updates/pages/frontend/index' /* webpackChunkName: "" */))
const _98eeecfa = () => interopDefault(import('../src/modules/updates/pages/frontend/show' /* webpackChunkName: "" */))
const _08e200fb = () => interopDefault(import('../src/modules/updates/pages/admin/index' /* webpackChunkName: "" */))
const _f95076ec = () => interopDefault(import('../src/modules/updates/pages/admin/select-loan' /* webpackChunkName: "" */))
const _0959cdf3 = () => interopDefault(import('../src/modules/updates/pages/admin/create' /* webpackChunkName: "" */))
const _f72f267e = () => interopDefault(import('../src/modules/updates/pages/admin/edit' /* webpackChunkName: "" */))
const _26c574f9 = () => interopDefault(import('../src/modules/users/pages/admin/index' /* webpackChunkName: "" */))
const _e2fb8458 = () => interopDefault(import('../src/modules/users/pages/admin/index/user-search' /* webpackChunkName: "" */))
const _d4e1bdc0 = () => interopDefault(import('../src/modules/users/pages/admin/index/re-bulk-update' /* webpackChunkName: "" */))
const _f38433fe = () => interopDefault(import('../src/modules/users/pages/admin/index/bulk-create' /* webpackChunkName: "" */))
const _66f88f5c = () => interopDefault(import('../src/modules/users/pages/admin/mfi-index' /* webpackChunkName: "" */))
const _b3220278 = () => interopDefault(import('../src/modules/users/pages/admin/user' /* webpackChunkName: "" */))
const _23094b16 = () => interopDefault(import('../src/modules/users/pages/admin/user/edit' /* webpackChunkName: "" */))
const _5a8b1bd1 = () => interopDefault(import('../src/modules/users/pages/admin/user/gift-aid-history' /* webpackChunkName: "" */))
const _275c1833 = () => interopDefault(import('../src/modules/users/pages/admin/user/email-change-history' /* webpackChunkName: "" */))
const _690f934e = () => interopDefault(import('../src/modules/users/pages/admin/transactions' /* webpackChunkName: "" */))
const _2744dae4 = () => interopDefault(import('../src/modules/users/pages/admin/roles' /* webpackChunkName: "" */))
const _b329a908 = () => interopDefault(import('../src/modules/users/pages/admin/mfis' /* webpackChunkName: "" */))
const _b036389c = () => interopDefault(import('../src/modules/users/pages/admin/credit' /* webpackChunkName: "" */))
const _1347d710 = () => interopDefault(import('../src/modules/users/pages/frontend/my-account' /* webpackChunkName: "" */))
const _9603f9b4 = () => interopDefault(import('../src/modules/users/pages/frontend/my-loans' /* webpackChunkName: "" */))
const _3b3724d8 = () => interopDefault(import('../src/modules/users/pages/frontend/my-grants' /* webpackChunkName: "" */))
const _73fe9702 = () => interopDefault(import('../src/modules/users/pages/frontend/my-vouchers' /* webpackChunkName: "" */))
const _1730d252 = () => interopDefault(import('../src/modules/users/pages/frontend/my-transactions' /* webpackChunkName: "" */))
const _09a49da9 = () => interopDefault(import('../src/modules/users/pages/frontend/add-credit' /* webpackChunkName: "" */))
const _991df4d0 = () => interopDefault(import('../src/modules/users/pages/frontend/withdraw-credit' /* webpackChunkName: "" */))
const _8af92144 = () => interopDefault(import('../src/modules/users/pages/frontend/withdraw-credit/index/index' /* webpackChunkName: "" */))
const _3f55a294 = () => interopDefault(import('../src/modules/users/pages/frontend/withdraw-credit/completed' /* webpackChunkName: "" */))
const _11e53fb6 = () => interopDefault(import('../src/modules/users/pages/frontend/profile' /* webpackChunkName: "" */))
const _334eede4 = () => interopDefault(import('../src/modules/users/pages/frontend/edit' /* webpackChunkName: "" */))
const _69904658 = () => interopDefault(import('../src/modules/users/pages/frontend/close-account' /* webpackChunkName: "" */))
const _21b6e27a = () => interopDefault(import('../src/modules/vouchers/pages/frontend/index' /* webpackChunkName: "" */))
const _1839ae5b = () => interopDefault(import('../src/modules/vouchers/pages/frontend/free' /* webpackChunkName: "" */))
const _044a11cb = () => interopDefault(import('../src/modules/vouchers/pages/frontend/redeem' /* webpackChunkName: "" */))
const _aefbe13e = () => interopDefault(import('../src/modules/vouchers/pages/admin' /* webpackChunkName: "" */))
const _542115d6 = () => interopDefault(import('../src/modules/vouchers/pages/admin/listing' /* webpackChunkName: "" */))
const _003dc505 = () => interopDefault(import('../src/modules/vouchers/pages/admin/bulk-purchases' /* webpackChunkName: "" */))
const _1f2ee77a = () => interopDefault(import('../src/modules/vouchers/pages/admin/bulk-purchases/listing' /* webpackChunkName: "" */))
const _65bafd46 = () => interopDefault(import('../src/modules/vouchers/pages/admin/bulk-purchases/create' /* webpackChunkName: "" */))
const _016be008 = () => interopDefault(import('../src/modules/withdrawals/pages/admin/completed' /* webpackChunkName: "" */))
const _7e67812b = () => interopDefault(import('../src/modules/withdrawals/pages/admin/requested' /* webpackChunkName: "" */))
const _fd039b36 = () => interopDefault(import('../src/modules/withdrawals/pages/admin/batches' /* webpackChunkName: "" */))
const _6c94be94 = () => interopDefault(import('../src/modules/withdrawals/pages/admin/pending' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/foo",
    component: _88998a58,
    name: "foo"
  }, {
    path: "/ie",
    component: _36cdfeee,
    name: "ie"
  }, {
    path: "/ie/ie",
    component: _73e0f6fd,
    name: "ie-ie"
  }, {
    path: "/admin",
    component: _3b105fc0,
    name: "admin:index"
  }, {
    path: "/angels",
    component: _2d5ebbd4,
    name: "angels:frontend:index"
  }, {
    path: "/angels/thank-you",
    component: _0d176200,
    name: "angels:frontend:thank-you"
  }, {
    path: "/appeals/:appealSlug",
    component: _f1126706,
    name: "appeals:frontend:show"
  }, {
    path: "/register/:referralCode?",
    component: _aa739564,
    name: "auth:register"
  }, {
    path: "/login",
    component: _5a32609e,
    name: "auth:login"
  }, {
    path: "/forgot-password",
    component: _1fb764fa,
    name: "auth:forgot-password"
  }, {
    path: "/reset-password",
    component: _62ecc3c4,
    name: "auth:reset-password"
  }, {
    path: "/basket",
    component: _b7e00d3c,
    children: [{
      path: "",
      component: _ae14a1da,
      name: "basket:frontend:index"
    }, {
      path: "payment",
      component: _5aace659,
      name: "basket:frontend:payment"
    }, {
      path: "payment-details",
      component: _d4c15a64,
      name: "basket:frontend:payment-details"
    }, {
      path: "login",
      component: _ba24c708,
      name: "basket:frontend:login"
    }, {
      path: "receipt/:uuid",
      component: _6bd9b2ea,
      name: "basket:frontend:receipt"
    }]
  }, {
    path: "/info",
    component: _c4075e0c
  }, {
    path: "/info/:uri*",
    component: _c4075e0c,
    name: "cms:article"
  }, {
    path: "/news/latest",
    component: _bee10124,
    name: "cms:news"
  }, {
    path: "/news/post/:id",
    component: _16ac3e12,
    name: "cms:news-item"
  }, {
    path: "/donate",
    component: _87523768,
    name: "donations:frontend:donate"
  }, {
    path: "/admin/mfis/entrepreneurs",
    component: _5b32d92a,
    name: "entrepreneurs:admin:index"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/create",
    component: _70678983,
    name: "entrepreneurs:admin:create"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)",
    component: _27cfcc51,
    name: "entrepreneurs:admin:edit"
  }, {
    path: "/admin/mfis/entrepreneurs/select-mfi",
    component: _7b1282b4,
    name: "entrepreneurs:admin:select-mfi"
  }, {
    path: "/admin/grant-repayments/mfis/due",
    component: _ef1e4292,
    name: "grant-repayments:admin:mfis:due:index"
  }, {
    path: "/admin/grant-repayments/mfis/:mfiId(\\d+)/due",
    component: _63c59115,
    name: "grant-repayments:admin:mfis:due:mfi"
  }, {
    path: "/admin/grant-repayments/mfis/archive",
    component: _672ab805,
    name: "grant-repayments:admin:archive:index"
  }, {
    path: "/admin/grant-repayments/mfis/:mfiId(\\d+)/archive",
    component: _6a2165e3,
    name: "grant-repayments:admin:archive:mfi"
  }, {
    path: "/admin/mfis/grant-reports",
    component: _7445972e,
    name: "grantReporting:admin:current"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/grant-reports/:reportId(\\d+)",
    component: _6c139dbe,
    name: "grantReporting:admin:show"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/grant-reports/no-current-report",
    component: _2e7abc20,
    name: "grantReporting:admin:no-current-report"
  }, {
    path: "/admin/mfis/grant-reports/archive",
    component: _4e5dafc4,
    name: "grantReporting:admin:archive"
  }, {
    path: "/grants",
    component: _f1bd6e40,
    name: "grants:frontend:home"
  }, {
    path: "/grants/search",
    component: _5625bd69,
    name: "grants:frontend:search"
  }, {
    path: "/grants/:grantId(\\d+)",
    component: _f1b3a204,
    name: "grants:frontend:show"
  }, {
    path: "/admin/mfis/entrepreneurs/grants",
    component: _2d10c936,
    name: "grants:admin:index"
  }, {
    path: "/admin/mfis/entrepreneurs/grants/select-entrepreneur",
    component: _7b1ab02e,
    name: "grants:admin:select-entrepreneur"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)/grants/create",
    component: _6b040d18,
    name: "grants:admin:create"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)/grants/:grantId(\\d+)",
    component: _01723126,
    name: "grants:admin:edit"
  }, {
    path: "/",
    component: _605167cd,
    name: "homepage:index"
  }, {
    path: "/search",
    component: _837b0632,
    name: "loans:frontend:search"
  }, {
    path: "/loans/:loanId(\\d+)",
    component: _0dc8b5fc,
    name: "loans:frontend:show"
  }, {
    path: "/admin/mfis/entrepreneurs/loans",
    component: _bff61098,
    name: "loans:admin:index"
  }, {
    path: "/admin/mfis/entrepreneurs/loans/select-entrepreneur",
    component: _5a24aa2c,
    name: "loans:admin:select-entrepreneur"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)/loans/create",
    component: _5695ae5a,
    name: "loans:admin:create"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)/loans/:loanId(\\d+)",
    component: _37c18a30,
    name: "loans:admin:edit"
  }, {
    path: "/admin/mfis/entrepreneurs/loans/messages",
    component: _7fd72f2a,
    name: "loans:admin:messages:index"
  }, {
    path: "/info/about-us/lendwithcare-shop",
    component: _2d6e1ad0,
    name: "marketing:frontend:shop"
  }, {
    path: "/welcome-answer/:answer",
    component: _c3455caa,
    name: "marketing:user-journey:answer"
  }, {
    path: "/admin/mfis",
    component: _71056cbc,
    name: "mfis:admin:index"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)",
    component: _487df33a,
    name: "mfis:admin:edit"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/administrators",
    component: _19fc6034,
    name: "mfis:admin:administrators"
  }, {
    path: "/admin/mfis/mfi-info-messages",
    component: _16c8e6c1,
    name: "mfis:admin:mfiInfoMessages"
  }, {
    path: "/admin/mfis/mfi-info-messages/:mfiInfoMessageId(\\d+)",
    component: _42cab63b,
    name: "mfis:admin:editMfiInfoMessages"
  }, {
    path: "/admin/mfis/offset-reports",
    component: _5da27a60,
    name: "offsetting:admin:current"
  }, {
    path: "/admin/mfis/offset-reports/archive",
    component: _42fbf260,
    name: "offsetting:admin:archive"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/offset-reports/:offsetReportId(\\d+)",
    component: _8e91c0a2,
    name: "offsetting:admin:edit"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/offset-reports/no-current-report",
    component: _6c7a4a22,
    name: "offsetting:admin:no-current-report"
  }, {
    path: "/my-account/make-referral",
    component: _4b19522c,
    name: "referral:frontend:make-referral"
  }, {
    path: "/referral/:referralCode?",
    component: _7c00308f,
    name: "referral:frontend:referral"
  }, {
    path: "/admin/repayments",
    component: _54e27602,
    name: "repayments:admin:index"
  }, {
    path: "/admin/repayments/archive",
    component: _0c8625af,
    name: "repayments:admin:archive"
  }, {
    path: "/admin/reporting",
    component: _7ebfb67b,
    children: [{
      path: "donations",
      component: _9a516ee6,
      name: "reporting:donations"
    }, {
      path: "reconciliation",
      component: _0a0978f7,
      name: "reporting:reconciliation"
    }, {
      path: "user-changes",
      component: _2cd35c56,
      name: "reporting:user-changes-download"
    }, {
      path: "user-data",
      component: _67e6a9d8,
      name: "reporting:user-data-download"
    }, {
      path: "gift-aid",
      component: _558495f8,
      name: "reporting:gift-aid-data-download"
    }, {
      path: "entrepreneur-and-loan-data",
      component: _07d3c65e,
      name: "reporting:entrepreneur-and-loan-data-download"
    }]
  }, {
    path: "/admin/site-settings",
    component: _15f11c5a,
    name: "site-settings:index",
    children: [{
      path: "/admin/site-settings/loan-activity-types",
      component: _4a9a6cfa,
      name: "site-settings:loan-activity-types"
    }, {
      path: "/admin/site-settings/user-sources",
      component: _3396316a,
      children: [{
        path: "",
        component: _e6f01e3c,
        name: "site-settings:user-sources:list"
      }, {
        path: "create",
        component: _7b6be040,
        name: "site-settings:user-sources:create"
      }, {
        path: ":userSourceId(\\d+)",
        component: _e6f6a364,
        name: "site-settings:user-sources:edit"
      }]
    }]
  }, {
    path: "/my-account/teams/create",
    component: _5fb7702e,
    name: "teams:frontend:create"
  }, {
    path: "/my-account/teams/:uuid",
    component: _6fdc6c97,
    name: "teams:frontend:manage"
  }, {
    path: "/teams",
    component: _4579ab60,
    name: "teams:frontend:index"
  }, {
    path: "/teams/:slug",
    component: _4776a0b7,
    name: "teams:frontend:profile"
  }, {
    path: "/updates",
    component: _860369e8,
    name: "updates:frontend:index"
  }, {
    path: "/updates/:updateId(\\d+)",
    component: _98eeecfa,
    name: "updates:frontend:show"
  }, {
    path: "/admin/mfis/entrepreneurs/loans/updates",
    component: _08e200fb,
    name: "updates:admin:index"
  }, {
    path: "/admin/mfis/entrepreneurs/loans/updates/create/select-loan",
    component: _f95076ec,
    name: "updates:admin:select-loan"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)/loans/:loanId(\\d+)/updates/create",
    component: _0959cdf3,
    name: "updates:admin:create"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)/loans/:loanId(\\d+)/updates/:updateId(\\d+)",
    component: _f72f267e,
    name: "updates:admin:edit"
  }, {
    path: "/admin/users",
    component: _26c574f9,
    children: [{
      path: "",
      component: _e2fb8458,
      name: "users:admin:index"
    }, {
      path: "raisers-edge",
      component: _d4e1bdc0,
      name: "users:admin:re-bulk-update"
    }, {
      path: "bulk-create",
      component: _f38433fe,
      name: "users:admin:bulk-create"
    }]
  }, {
    path: "/admin/mfi-users",
    component: _66f88f5c,
    name: "users:admin:mfi-index"
  }, {
    path: "/admin/users/:userId(\\d+)",
    component: _b3220278,
    children: [{
      path: "",
      component: _23094b16,
      name: "users:admin:edit"
    }, {
      path: "gift-aid-history",
      component: _5a8b1bd1,
      name: "users:admin:gift-aid-history"
    }, {
      path: "email-change-history",
      component: _275c1833,
      name: "users:admin:email-change-history"
    }]
  }, {
    path: "/admin/users/:userId(\\d+)/transactions",
    component: _690f934e,
    name: "users:admin:transactions"
  }, {
    path: "/admin/users/:userId(\\d+)/roles",
    component: _2744dae4,
    name: "users:admin:roles"
  }, {
    path: "/admin/users/:userId(\\d+)/mfis",
    component: _b329a908,
    name: "users:admin:mfis"
  }, {
    path: "/admin/users/:userId(\\d+)/credit",
    component: _b036389c,
    name: "users:admin:credit"
  }, {
    path: "/my-account",
    component: _1347d710,
    name: "users:frontend:my-account"
  }, {
    path: "/my-account/my-loans",
    component: _9603f9b4,
    name: "users:frontend:my-loans"
  }, {
    path: "/my-account/my-grants",
    component: _3b3724d8,
    name: "users:frontend:my-grants"
  }, {
    path: "/my-account/my-gift-vouchers",
    component: _73fe9702,
    name: "users:frontend:my-vouchers"
  }, {
    path: "/my-account/my-transactions",
    component: _1730d252,
    name: "users:frontend:my-transactions"
  }, {
    path: "/my-account/add-credit",
    component: _09a49da9,
    name: "users:frontend:add-credit"
  }, {
    path: "/my-account/withdraw-credit",
    component: _991df4d0,
    children: [{
      path: "",
      component: _8af92144,
      name: "users:frontend:withdraw-credit"
    }, {
      path: "completed",
      component: _3f55a294,
      name: "users:frontend:withdrawal-complete"
    }]
  }, {
    path: "/profile/:userUuid",
    component: _11e53fb6,
    name: "users:frontend:profile"
  }, {
    path: "/my-account/my-details",
    component: _334eede4,
    name: "users:frontend:edit-details"
  }, {
    path: "/my-account/my-profile",
    component: _334eede4,
    name: "users:frontend:edit-profile"
  }, {
    path: "/my-account/my-settings",
    component: _334eede4,
    name: "users:frontend:edit-settings"
  }, {
    path: "/my-account/close/:token",
    component: _69904658,
    name: "users:frontend:close-account"
  }, {
    path: "/gift-vouchers",
    component: _21b6e27a,
    name: "vouchers:frontend:index"
  }, {
    path: "/gift-vouchers/claim-free",
    component: _1839ae5b,
    name: "vouchers:frontend:free"
  }, {
    path: "/gift-vouchers/redeem",
    component: _044a11cb,
    name: "vouchers:frontend:redeem"
  }, {
    path: "/admin/vouchers",
    component: _aefbe13e,
    children: [{
      path: "",
      component: _542115d6,
      name: "vouchers:admin:list"
    }, {
      path: "/admin/vouchers/bulk-purchases",
      component: _003dc505,
      children: [{
        path: "",
        component: _1f2ee77a,
        name: "vouchers:admin:bulk-purchases"
      }, {
        path: "new",
        component: _65bafd46,
        name: "vouchers:admin:create-bulk-purchase"
      }]
    }]
  }, {
    path: "/admin/withdrawals/completed",
    component: _016be008,
    name: "withdrawals:admin:completed"
  }, {
    path: "/admin/withdrawals/requested",
    component: _7e67812b,
    name: "withdrawals:admin:requested"
  }, {
    path: "/admin/withdrawals/batches",
    component: _fd039b36,
    name: "withdrawals:admin:batches"
  }, {
    path: "/admin/withdrawals/pending",
    component: _6c94be94,
    name: "withdrawals:admin:pending"
  }],

  parseQuery: function (queryString) {
      return window.qs.parse(queryString, {
        allowDots: true,
        arrayFormat: 'brackets',
        ignoreQueryPrefix: true
      });
    },
  stringifyQuery: function (queryObject) {
      return window.qs.stringify(queryObject, {
        addQueryPrefix: true,
        allowDots: true,
        arrayFormat: 'brackets',
        encode: false
      });
    },
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
